<template>
  <div class="thing_category_add">
    <el-card>
      <el-row :gutter="20" class="left">
        <el-col :span="3">
          <i class="el-icon-arrow-left" style="color: #999; cursor: pointer" @click="back">
            新增分类
          </i>
        </el-col>
      </el-row>
      <el-divider></el-divider>
      <el-form ref="categoryForm" :rules="categoryRules" :model="info" label-width="120px" style="padding-left:40px">
        <el-row class="left">
          <el-col :span="24">
            <el-form-item label="分类封面" prop="category_thumb">
              <div class="previewUpload">
                <div v-show="info.category_thumb" class="previewItem">
                  <img :src="info.category_thumb" alt="">
                  <i class="el-icon-close delIcon" @click="delThumb(index)"></i>
                </div>
                <div class="previewItems" @click="openUploadDialog(1,'category_thumb')"><i class="el-icon-plus previewadd"></i></div>
              </div>
              <Upload :uploadVisible.sync="uploadVisible" :pictureType="pictureType" :chooseImgCount="chooseImgCount" @close="closeDelDialog" @receiveRes="receiveRes"></Upload>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="left">
          <el-col :span="8">
            <el-form-item label="上级分类" prop="parent_id">
              <treeselect v-model="info.parent_id" :options="category_list" :normalizer="normalizer" :show-count="true" placeholder="请选择上级菜单" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="分类名称" prop="category_name">
              <el-input v-model="info.category_name" maxlength="15" show-word-limit placeholder="请输入分类名称"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="left">
          <el-col :span="8">
            <el-form-item label="是否显示" prop="is_show">
              <el-radio v-model="info.is_show" label="1">是</el-radio>
              <el-radio v-model="info.is_show" label="0">否</el-radio>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="left">
          <el-col :span="8">
            <el-form-item label="排序" prop="sort_order">
              <el-input v-model.number="info.sort_order" placeholder="请输入排序"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="left">
          <el-col :span="8">
            <el-form-item label="描述" prop="category_desc">
              <el-input type="textarea" :rows="4" v-model="info.category_desc" placeholder="请输入描述"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-button type="primary" @click="subAdd">提交</el-button>
      <el-button type="default" @click="back">返回</el-button>
    </el-card>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination/Pagination.vue'
import Upload from '@/components/Upload/Upload.vue'
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
export default {
  components:{
    Pagination,Upload,Treeselect
  },
  data () {
    return {
      categoryRules:{
        category_name:[
          {required: true, message:'请输入分类名称', trigger: 'blur'}
        ]
      },
      info:{
      	category_id: '',
        category_name:'',
        category_desc:'',
        parent_id:'0',
        sort_order:99,
        is_show: '1',
        category_thumb:'',
      },
      category_list: [],

      //上传图片
      uploadVisible:false,
      pictureType:'',
      chooseImgCount:0,
      
    }
  },
  created() {
    if(!this.$store.state.userInfo) {
			// 防止未登录
			this.$router.push({
				path: '/login'
			})
		} else {
			this.getCategoryList();
			if(this.$route.query.id){
				this.getCategoryDetail();
			}
		}
  },
  methods: {
    getCategoryList(){
      var url = 'thing/category_list_select';
      let params = {};
      this.fd_post(url, params).then((res) => {
        console.log(res);
        if(res.status) {
          this.category_list = res.list;
        } else {
          this.$message.error(res.msg);
        }
      }).catch((err) => {
        this.$message.error('网络错误');
      });
    },
  	getCategoryDetail(){
  		var url = 'thing/category_detail';
			let params = {
				category_id: this.$route.query.id
			};
			this.fd_post(url, params).then((res) => {
				if(res.status) {
					this.info = res.detail;
				} else {
					this.$message.error(res.msg);
				}
			}).catch((err) => {
				this.$message.error('网络错误');
			});
  	},
    normalizer(node) {
      console.log(node);
      if(node.children && !node.children.length) {
        delete node.children;
      }
      return {
        id: node.category_id,
        label: node.category_name,
        children: node.children,
      };
    },
    back(){
      this.$router.go(-1)
    },
    //上传图片
    openUploadDialog(count,pictureType){
      this.pictureType = pictureType;
      this.chooseImgCount = count;
      this.uploadVisible = true;
    },
    closeDelDialog(){
      this.uploadVisible=false;
    },
    receiveRes(chooseList, pictureType){
      console.log(chooseList);
      if(pictureType==='category_thumb'){
        this.info.category_thumb = chooseList[0].img_path;
      }
      this.uploadVisible=false;
    },
    delThumb(index){
      this.info.category_thumb = '';
    },
    //提交
    subAdd(){
      this.$refs.categoryForm.validate(valid=>{
        console.log(this.info);
        if(!valid) return;
        else{
	        this.fd_post('thing/category_doedit', this.info).then((res) => {
        		if(res.status){
        			this.$refs.categoryForm.resetFields();
        			this.$message.success("操作成功");
        			this.$router.push({path: '/thing_category'});
        		}else{
        			this.$message.error(res.msg);
        		}
	        }).catch((err) => {
	            this.$message.error('网络错误');
	        });
        }
      })
    }
  },
}
</script>

<style lang="scss" scoped>
  .thing_category_add{
    .left{
      text-align: left;
    }
    #allmap{
      height: 450px;
      width: 100%;
      margin: 10px 0;
    }
    .previewUpload{
      display: flex;
      flex-wrap: wrap;
      .previewItems{
        width: 178px;
        height: 178px;
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        margin:0 10px 10px 0;
        cursor: pointer;
        position: relative;
        .previewadd {
          font-size: 28px;
          color: #8c939d;
          width: 178px;
          height: 178px;
          line-height: 178px;
          text-align: center;
        }
        &:hover{
          border-color: #409EFF;
        }
      }
      .previewItem{
        width: 178px;
        height: 178px;
        border-radius: 6px;
        margin:0 10px 10px 0;
        cursor: pointer;
        position: relative;
        .delIcon{
          display: none;
        }
        &:hover{
          .delIcon{
            display: block;
            position: absolute;
            top: 5px;
            right: 5px;
            color: white;
            font-size: 30px;
          }
        }
        
        img{
          width: 100%;
          height: 100%;
        }
        
      }
    }

  }
</style>